import React from "react"

import Card from "react-bootstrap/card"
import Container from "react-bootstrap/container"
import Row from "react-bootstrap/row"
import Col from "react-bootstrap/col"

import PageLayout from "components/pageLayout"
import ContactForm from "components/form/contact-form"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import contactStyles from "../styles/contact.module.less"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query GetPortraitPhoto {
      file(
        sourceInstanceName: { eq: "portrait" }
        relativePath: { eq: "portrait.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <Container fluid className={contactStyles.contact}>
        <Row>
          <Col
            lg={6}
            md={6}
            className={`d-flex flex-column ${contactStyles.owner}`}
          >
            <Col className={contactStyles.ownerImg}>
              <Img fluid={data.file.childImageSharp.fluid} />
            </Col>
            <Col>
              <Card.Title>Meet the Owner</Card.Title>
              <Card.Text>
                Hi, I'm Mike! I've owned Add Lock Storage for 20 years. I
                graduated from The Ohio State University with a degree in
                Agriculture, so alongside managing Add Lock I am also a
                beekeeper and farmer.
              </Card.Text>
              <Card.Text>
                Please contact me at (513)-539-5111, or submit an inquiry, for
                more information about what units are currently available.
              </Card.Text>
            </Col>
          </Col>
          <Col lg={6} md={6}>
            <Card
              className={contactStyles.form}
              body
              style={{ backgroundColor: "#DBDBDB" }}
            >
              <ContactForm />
            </Card>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

// best time to contact

export default Contact
