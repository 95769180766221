import React, { useRef, useState } from "react"

import Form from "react-bootstrap/form"
import Spinner from "react-bootstrap/spinner"
import Button from "react-bootstrap/button"
import { Formik } from "formik"

import contactStyles from "styles/contact.module.less"

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef(null)

  const handleSubmit = async (values, {resetForm}) => {
    setIsLoading(true)
    await fetch(process.env.GOOGLE_SHEET_URL, {
      method: "POST",
      body: new FormData(formRef.current),
    })

    setIsLoading(false)
    resetForm()
  }

  const validateForm = values => {
    let errors = {}
    if (!values["first_name"]) {
      errors.firstName = "First name is required"
    }

    if (!values["last_name"]) {
      errors.lastName = "Last name is required"
    }

    if (!values["phone_number"]) {
      errors.phoneNumber = "Phone number is required"
    }

    if (!values["email_address"]) {
      errors.emailAddress = "Email address is required"
    } 
    else if (
      values["email_address"] &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values["email_address"])
    ) {
      errors.emailAddress = "Please enter a valid email address"
    }

    return errors
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        // using snake case because the google form expects the name to be this format for now
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        description: "",
      }}
      validate={validateForm}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form
          className={contactStyles.form}
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit()
        }}
          noValidate
        >
          <Form.Group controlId="formFirstName">
            <Form.Label className="text-dark">First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["first_name"]}
              isValid={touched["first_name"] && !errors.firstName}
              isInvalid={touched["first_name"] && errors.firstName}
              required
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label className="text-dark">Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["last_name"]}
              isValid={touched["last_name"] && !errors.lastName}
              isInvalid={touched["last_name"] && errors.lastName}
              required
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label className="text-dark">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              name="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["phone_number"]}
              isValid={touched["phone_number"] && !errors.phoneNumber}
              isInvalid={touched["phone_number"] && errors.phoneNumber}
              required
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label className="text-dark">Email Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email Address"
              name="email_address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["email_address"]}
              isValid={touched["email_address"] && !errors.emailAddress}
              isInvalid={touched["email_address"] && errors.emailAddress}
              required
              autoComplete="off"
            />
            <Form.Text className="text-dark">
              We'll never share your email with anyone else.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.emailAddress}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formMessage">
            <Form.Label className="text-dark">
              What are you looking for: (optional)
            </Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              placeholder="Please enter a description..."
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              isValid={touched.description && !errors.description}
              autoComplete="off"
            />
          </Form.Group>

          {isLoading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm

// add best time to contact field
// try to add email validation?
